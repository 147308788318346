<template>
  <div class="header py-0 shadow bg-white mt-3">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row py-3">
          <nav aria-label="breadcrumb" class="ml-2">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item">
                <a href="#"><i class="fas fa-link"></i></a>
              </li>
              <li class="breadcrumb-item"><a href="#"></a></li>
              All products
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-2">
    <button
      class="mb-2 btn btn-default btn-sm"
      @click="$router.push({ name: 'New Product' })"
    >
      Add New Product
    </button>
    <div class="card shadow w-100 p-4">
      <loader v-if="!data_loaded" />
      <data-table
        v-if="data_loaded"
        id="collections_list"
        :options="dtb_options.extra_options"
        :actions="dtb_options.actions"
        :data="dtb_options.data"
        :columns="dtb_options.columns"
        :on_click="dtb_on_click"
      />
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/Datatable";
import IndependentLoader from "../../components/IndependentDefaultLoader";
import {
  getDocs,
  collection,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { alert } from "../../utils/alertUtils";

export default {
  name: "Products",
  components: {
    "data-table": DataTable,
    loader: IndependentLoader,
  },
  data() {
    return {
      th: this,
      data_loaded: false,
      collections: [],
      //   user_profile: this.$store.getters.getUserProfile,
      dtb_options: {
        data: [],
        actions: [
          // {
          //   btnText: "Delete",
          //   btnClass: "btn btn-white text-danger btn-sm",
          //   iconClass: "fas fa-trash",
          //   btnFn: (uuid) => {
          //     this.delete_collection(uuid);
          //   },
          //   btnParamName: "uuid",
          // },
          // {
          //   btnText: "Update",
          //   btnClass: "btn btn-white text-[#154462] btn-sm",
          //   iconClass: "fas fa-edit",
          //   btnFn: (uuid) => {
          //     this.$router.push({
          //       name: "Product Update",
          //       params: { uuid },
          //     });
          //   },
          //   btnParamName: "uuid",
          // },
          {
            btnText: "Management",
            btnClass: "btn btn-white text-info btn-sm",
            iconClass: "fas fa-edit",
            btnFn: (uuid) => {
              this.$router.push({
                name: "Product Management",
                params: { uuid },
              });
            },
            btnParamName: "uuid",
          },
        ],
        columns: [
          {
            title: "Name",
            data: "name",
          },
          {
            title: "product id",
            data: "uuid",
          },
        ],
        extra_options: {},
      },
    };
  },
  methods: {
    dtb_on_click: function (row) {
      var uuid = row.uuid;
      console.log(uuid);
      //  this.$router.push({ name: "Accomodation Details", params: { uuid } });
    },
    // delete_collection: function(uuid) {
    //   alert.promptDelete(this.implement_delete_collection(uuid));
    // },

    delete_collection: function (uuid) {
      const ref = doc(db, "products", uuid);
      deleteDoc(ref)
        .then(() => {
          this.dtb_options.data = [];
          this.fetch_products();
          alert.success_center("You have deleted successfully");
        })
        .catch(() => {
          alert.error("Error occured in deleting carrier");
        });
    },

    fetch_products: async function () {

      const productsRef = collection(db, "products");
      const q = query(
        productsRef,
        where("user_id", "==", this.$store.getters.getUserId)
      );

      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        this.dtb_options.data = []
        this.data_loaded =  true
      }else{
              querySnapshot.forEach((doc) => {
        let responseData = doc.data();
        responseData.uuid = doc.id;
        this.dtb_options.data.push(responseData);
        this.data_loaded = true;
      });
      }


      // fetch collections
      // getDocs(collection(db, "products"))
      //   .then((snapShots) => {
      //     snapShots.forEach((document) => {
      //       let collections = document.data();
      //       collections.uuid = document.id;
      //       this.dtb_options.data.push(collections);
      //     });
      //   })
      //   .then(() => {
      //     this.data_loaded = true;
      //   })
      //   .catch((e) => {
      //     console.log(`an error occured ${e}`);
      //     this.data_loaded = false;
      //   });
    },
  },
  created() {
    this.fetch_products();
  },
};
</script>

<style></style>
